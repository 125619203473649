.container {
    display: flex;
    flex-direction: column;
    gap: calc(20 / 1512 * 100vw);
    align-items: center;
}

.nameContainer {
    text-align: center;
    padding: calc(20 / 1512 * 100vw);
    border-radius: calc(20 / 1512 * 100vw);
    background: #de374b;
    width: 30%;
    color: #fff;
    position: sticky;
    top: 10px;
    z-index: 10;
}

.name {
    font-size: calc(24 / 1512 * 100vw);
    font-weight: bold;
}

.timeStamp {
    font-size: calc(16 / 1512 * 100vw);
}

.allItemsGroupedContainer {
    width: 30%;
}

.groupedItems {
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    width: 100%;
    margin-bottom: calc(30 / 1512 * 100vw);
}

.itemsContainer {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.4);
    padding: calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    gap: calc(10 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}



.itemTitle {
    text-transform: capitalize;
    font-size: calc(15 / 1512 * 100vw);
}

.icon,
.angleRight {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.angleRight {
    margin-left: auto;
}

.heading {
    font-size: calc(16 / 1512 * 100vw);
    margin-top: calc(20 / 1512 * 100vw);
    margin-bottom: calc(10 / 1512 * 100vw);
    color: #de374b;
    font-weight: bold;
}

.logOutBtn {
    background: #de374b;
    width: 100%;
    padding: calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    margin-top: calc(10 / 1512 * 100vw);
    border: none;
    outline: none;
    font-size: calc(16 / 1512 * 100vw);
    color: #fff;
    cursor: pointer;
    transition: 0.5s all;

}


.itemsContainer:hover,
.logOutBtn:hover {
    opacity: 0.5;
}

@media screen and (max-width: 480px) {
    .container {
        display: flex;
        flex-direction: column;
        gap: calc(20 / 414 * 100vw);
        align-items: center;
    }

    .nameContainer {
        text-align: center;
        padding: calc(20 / 414 * 100vw);
        border-radius: calc(20 / 414 * 100vw);
        background: #de374b;
        width: 100%;
        color: #fff;
       
    }

    .name {
        font-size: calc(20 / 414 * 100vw);
        font-weight: bold;
    }

    .timeStamp {
        font-size: calc(16 / 414 * 100vw);
    }

    .allItemsGroupedContainer {
        width: 100%;
    }

    .groupedItems {
        display: flex;
        flex-direction: column;
        gap: calc(10 / 414 * 100vw);
        width: 100%;
        margin-bottom: calc(30 / 414 * 100vw);
    }

    .itemsContainer {
        display: flex;
        align-items: center;
        background: rgba(255, 255, 255, 0.4);
        padding: calc(20 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        gap: calc(10 / 414 * 100vw);
        cursor: pointer;
        transition: 0.5s all;
    }



    .itemTitle {
        text-transform: capitalize;
        font-size: calc(14 / 414 * 100vw);
    }

    .icon,
    .angleRight {
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }

    .angleRight {
        margin-left: auto;
    }

    .heading {
        font-size: calc(16 / 414 * 100vw);
        margin-top: calc(20 / 414 * 100vw);
        margin-bottom: calc(10 / 414 * 100vw);
        color: #de374b;
        font-weight: bold;
    }

    .logOutBtn {
        background: #de374b;
        width: 100%;
        padding: calc(20 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        margin-top: calc(10 / 414 * 100vw);
        border: none;
        outline: none;
        font-size: calc(16 / 414 * 100vw);
        color: #fff;
        cursor: pointer;
        transition: 0.5s all;
        margin-bottom: calc(200 / 414 * 100vw);
    }


    .itemsContainer:hover,
    .logOutBtn:hover {
        opacity: 0.5;
    }


}