.container {
    width: 74%;
    height: calc(100vh - calc(150 / 1512 * 100vw));
    background: rgba(255, 255, 255, 0.44);
    border-radius: calc(20 / 1512 * 100vw);
    padding-top: calc(100 / 1512 * 100vw);
    flex-direction: column;
    display: flex;
    align-items: center;
}

.subContainer {
    width: 50%;
}

.head {
    font-size: calc(20 / 1512 * 100vw);
    margin-bottom: calc(20 / 1512 * 100vw);
    font-weight: bold;
    text-align: center;
}

.inputContainer {
    width: 100%;
    background: #fff;
    height: calc(60 / 1512 * 100vw);
    padding: 0 calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    margin-bottom: calc(20 / 1512 * 100vw);
    display: flex;
    align-items: center;
}

.input {
    width: 100%;
    height: 100%;
    padding: calc(20 / 1512 * 100vw) 0;
    outline: none;
    font-size: calc(14 / 1512 * 100vw);
    border: none;
    background: #fff;
}

.fileInput {
    display: none;
}

.fileLabel {
    width: 100%;
    height: 100%;
    color: #a9a9a9;
    border-radius: calc(10 / 1512 * 100vw);
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: calc(14 / 1512 * 100vw);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


.input::placeholder {
    font-size: calc(14 / 1512 * 100vw);
    color: #a9a9a9;
}

.button {
    width: 100%;
    height: calc(60 / 1512 * 100vw);
    background-color: #de374b;
    color: #fff;
    border: none;
    border-radius: calc(10 / 1512 * 100vw);
    font-size: calc(20 / 1512 * 100vw);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: calc(20 / 1512 * 100vw);
}


@media screen and (max-width: 480px) {
    .container {
        width: 100%;
        height: 65vh;
        background: rgba(255, 255, 255, 0.44);
        border-radius: calc(10 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw);
        padding-top: calc(50 / 414 * 100vw);
        flex-direction: column;
        display: flex;
        align-items: center;
    }

    .subContainer {
        width: 100%;
    }

    .head {
        font-size: calc(18 / 414 * 100vw);
        margin-bottom: calc(20 / 414 * 100vw);
        font-weight: bold;
        text-align: center;
    }

    .inputContainer {
        width: 100%;
        background: #fff;
        height: calc(60 / 414 * 100vw);
        padding: 0 calc(10 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
        margin-bottom: calc(10 / 414 * 100vw);
        display: flex;
        align-items: center;
    }

    .input {
        width: 100%;
        height: 100%;
        padding: calc(10 / 414 * 100vw) 0;
        outline: none;
        font-size: calc(14 / 414 * 100vw);
        border: none;
    }

    .fileInput {
        display: none;
    }

    .fileLabel {
        width: 100%;
        height: 100%;
        color: #a9a9a9;
        border-radius: calc(10 / 414 * 100vw);
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: calc(14 / 414 * 100vw);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }


    .input::placeholder {
        font-size: calc(14 / 414 * 100vw);
        color: #a9a9a9;
    }

    .button {
        width: 100%;
        height: calc(50 / 414 * 100vw);
        background-color: #de374b;
        color: #fff;
        border: none;
        border-radius: calc(10 / 414 * 100vw);
        font-size: calc(16 / 414 * 100vw);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: calc(20 / 414 * 100vw);
    }

}