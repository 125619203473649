.container {
    display: none;
}

@media screen and (max-width: 480px) {
    .container {
        color: grey;
        position: fixed;
        bottom: calc(5 / 414 * 100vw);
        z-index: 10;
        width: 100%;
        padding: 0 calc(30 / 414 * 100vw);
        display: flex;
        justify-content: center;
        font-size: calc(14 / 414 * 100vw);
    }

    .subContainer {
        background: rgba(0, 0, 0, 0.85);
        display: flex;
        width: 85%;
        padding: calc(15 / 414 * 100vw);
        justify-content: center;
        gap: calc(50 / 414 * 100vw);
        border-radius: calc(20 / 414 * 100vw);
    }

    .navContainer {
        flex-direction: column;
        display: flex;
        align-items: center;
        gap: calc(2 / 414 * 100vw);
    }

    .icon {
        width: calc(18 / 414 * 100vw);
        height: calc(18 / 414 * 100vw);
    }

    .indicator {
        width: calc(15 / 414 * 100vw);
        height: calc(15 / 414 * 100vw);
        background: #de374b;
        border-radius: 50%;

    }
}