.loader {
  width: calc(20 / 1512 * 100vw);
  aspect-ratio: .99;
  --c: no-repeat linear-gradient(#fff 0 0);
  background:
    var(--c) 0% 100%,
    var(--c) 50% 100%,
    var(--c) 100% 100%;
  background-size: 20% 35%;
  animation: l5 1s infinite linear;
}

@keyframes l5 {
  20% {
    background-position: 0% 75%, 50% 100%, 100% 100%
  }

  40% {
    background-position: 0% 50%, 50% 75%, 100% 100%
  }

  60% {
    background-position: 0% 100%, 50% 50%, 100% 75%
  }

  80% {
    background-position: 0% 100%, 50% 100%, 100% 50%
  }
}


.orange {
  width: calc(20 / 1512 * 100vw);
  aspect-ratio: .99;
  --c: no-repeat linear-gradient(#de374b 0 0);
  background:
    var(--c) 0% 100%,
    var(--c) 50% 100%,
    var(--c) 100% 100%;
  background-size: 20% 35%;
  animation: l5 1s infinite linear;
}

@media screen and (max-width: 480px) {
  .loader {
    width: calc(20 / 414 * 100vw);
    aspect-ratio: .99;
    --c: no-repeat linear-gradient(#fff 0 0);
    background:
      var(--c) 0% 100%,
      var(--c) 50% 100%,
      var(--c) 100% 100%;
    background-size: 20% 35%;
    animation: l5 1s infinite linear;
  }

  @keyframes l5 {
    20% {
      background-position: 0% 75%, 50% 100%, 100% 100%
    }

    40% {
      background-position: 0% 50%, 50% 75%, 100% 100%
    }

    60% {
      background-position: 0% 100%, 50% 50%, 100% 75%
    }

    80% {
      background-position: 0% 100%, 50% 100%, 100% 50%
    }
  }


  .orange {
    width: calc(20 / 414 * 100vw);
    aspect-ratio: .99;
    --c: no-repeat linear-gradient(#de374b 0 0);
    background:
      var(--c) 0% 100%,
      var(--c) 50% 100%,
      var(--c) 100% 100%;
    background-size: 20% 35%;
    animation: l5 1s infinite linear;
  }
}