.container {
    width: 100%;
    display: flex;
    padding: calc(20 / 1512 * 100vw);
    flex-direction: column;
    gap: calc(20 / 1512 * 100vw);
    align-items: center;
}

.headerGrouped {
    flex-direction: column;
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    align-items: center;
}

.image {
    height: calc(200 / 1512 * 100vw);
    margin-top: calc(30 / 1512 * 100vw);
    object-fit: contain;
}

.title {
    font-size: calc(28 / 1512 * 100vw);
    font-weight: 900;
    color: #F24401;
}

.subtitle {
    font-size: calc(18 / 1512 * 100vw);
    color: gray;
}

.groupedPricing {
    display: flex;
    gap: calc(10 / 1512 * 100vw);

}

.pricingContainer {
    border: calc(1.5 / 1512 * 100vw) solid #F24401;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.5s all;
    cursor: pointer;
}

.pricingContainer:hover {
    transform: scale(1.1);
}

.duration {
    font-size: calc(30 / 1512 * 100vw);
    margin: calc(10 / 1512 * 100vw) 0;
    font-weight: bold;
}

.save {
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    border-radius: calc(20 / 1512 * 100vw);
    background: #F24401;
    color: #fff;
    font-size: calc(14 / 1512 * 100vw);
}

.loaderContainer {
    padding: calc(20 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background: #ffffff8f;
    align-self: center;
}

.button {
    padding: calc(20 / 1512 * 100vw);
    margin-top: calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    width: 30%;
    background: #F24401;
    outline: none;
    border: none;
    font-size: calc(16 / 1512 * 100vw);
    color: #fff;
    font-weight: bold;
}

.question {
    font-size: calc(16 / 1512 * 100vw);
    font-weight: bold;
    color: gray;
}

.answer {
    font-size: calc(16 / 1512 * 100vw);
    color: gray;
    width: 30%;
    text-align: center;
}

@media screen and (max-width: 480px) {
    .container {
        width: 100%;
        display: flex;
        padding: calc(20 / 414 * 100vw);
        flex-direction: column;
        gap: calc(20 / 414 * 100vw);
        align-items: center;
    }

    .headerGrouped {
        flex-direction: column;
        display: flex;
        gap: calc(10 / 414 * 100vw);
        align-items: center;
    }

    .image {
        height: calc(200 / 414 * 100vw);
        margin-top: calc(30 / 414 * 100vw);
        object-fit: contain;
    }

    .title {
        font-size: calc(28 / 414 * 100vw);
        font-weight: 900;
        color: #F24401;
    }

    .subtitle {
        font-size: calc(18 / 414 * 100vw);
        color: gray;
    }

    .groupedPricing {
        display: flex;
        gap: calc(10 / 414 * 100vw);
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: none;
        /* Hides scrollbar in Firefox */
        -ms-overflow-style: none;
        /* Hides scrollbar in Internet Explorer/Edge */
        padding: calc(10 / 414 * 100vw);

    }

    .groupedPricing::-webkit-scrollbar {
        display: none;
        /* Hides scrollbar in WebKit browsers (Chrome, Safari, etc.) */
    }


    .pricingContainer {
        border: calc(1.5 / 414 * 100vw) solid #F24401;
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.5s all;
        cursor: pointer;
    }

    .pricingContainer:hover {
        transform: scale(1.1);
    }

    .duration {
        font-size: calc(30 / 414 * 100vw);
        margin: calc(10 / 414 * 100vw) 0;
        font-weight: bold;
    }

    .save {
        padding: calc(10 / 414 * 100vw) calc(20 / 414 * 100vw);
        border-radius: calc(20 / 414 * 100vw);
        background: #F24401;
        color: #fff;
        font-size: calc(14 / 414 * 100vw);
        white-space: nowrap;
    }

    .loaderContainer {
        padding: calc(20 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
        background: #ffffff8f;
        align-self: center;
    }

    .button {
        padding: calc(20 / 414 * 100vw);
        margin-top: calc(20 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        width: 100%;
        background: #F24401;
        outline: none;
        border: none;
        font-size: calc(16 / 414 * 100vw);
        color: #fff;
        font-weight: bold;
    }

    .question {
        font-size: calc(16 / 414 * 100vw);
        font-weight: bold;
        color: gray;
    }

    .answer {
        font-size: calc(16 / 414 * 100vw);
        color: gray;
        width: 100%;
        text-align: center;
        margin-bottom: calc(100 / 414 * 100vw);
    }
}