.container {
    /* padding: calc(100 / 1512 * 100vw); */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}

.subContainer {
    width: 28%;
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(10 / 1512 * 100vw);
    padding: calc(40 / 1512 * 100vw);
}

.title {
    font-size: calc(18 / 1512 * 100vw);
    font-weight: bold;
    margin-bottom: calc(20 / 1512 * 100vw);
}

.input {
    width: 100%;
    padding: calc(15 / 1512 * 100vw);
    margin-bottom: calc(20 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    border: calc(1 / 1512 * 100vw) solid #ccc;
    outline: none;
}

.passwordContainer {
    display: flex;
    align-items: center;
    margin-bottom: calc(20 / 1512 * 100vw);
    border: calc(1 / 1512 * 100vw) solid #ccc;
    border-radius: calc(5 / 1512 * 100vw);
    overflow: hidden;
    background-color: #fff;
}

.passwordInput {
    flex: 1;
    padding: calc(15 / 1512 * 100vw);
    outline: none;
    border: none;
}

.eyeIcon {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 calc(10 / 1512 * 100vw);
    font-size: calc(16 / 1512 * 100vw);
    transition: 0.5s all;
}

.eyeIcon:hover {
    transform: scale(1.1);
}

.button {
    width: 100%;
    padding: calc(15 / 1512 * 100vw);
    background-color: #de374b;
    color: #fff;
    font-weight: bold;
    text-align: center;
    border-radius: calc(10 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
    outline: none;
    border: none;
    font-size: calc(14 / 1512 * 100vw);
    display: flex;
    justify-content: center;
    align-items: center;
}

.button:hover {
    opacity: 0.5;
}

.forgotPassword,
.prompt {
    margin-top: calc(10 / 1512 * 100vw);
    text-align: center;
    color: #de374b;
    font-size: calc(14 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.input,
.input::placeholder,
.passwordInput,
.passwordInput::placeholder {
    font-size: calc(14 / 1512 * 100vw);
}

.forgotPassword:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 480px) {
    .container {
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        height: 75vh;
    }

    .subContainer {
        width: 100%;
        background: rgba(255, 255, 255, 0.3);
        border-radius: calc(10 / 414 * 100vw);
        padding: calc(40 / 414 * 100vw);
        height: 100%;
    }

    .title {
        font-size: calc(18 / 414 * 100vw);
        font-weight: bold;
        margin-bottom: calc(20 / 414 * 100vw);
        text-align: center;
    }

    .input {
        width: 100%;
        padding: calc(15 / 414 * 100vw);
        margin-bottom: calc(20 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
        border: calc(1 / 414 * 100vw) solid #ccc;
        outline: none;

    }

    .input,
    .input::placeholder,
    .passwordInput,
    .passwordInput::placeholder {
        font-size: calc(12 / 414 * 100vw);
    }

    .passwordContainer {
        display: flex;
        align-items: center;
        margin-bottom: calc(20 / 414 * 100vw);
        border: calc(1 / 414 * 100vw) solid #ccc;
        border-radius: calc(5 / 414 * 100vw);
        overflow: hidden;
    }

    .passwordInput {
        flex: 1;
        padding: calc(15 / 414 * 100vw);
        outline: none;
        border: none;
    }

    .eyeIcon {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0 calc(10 / 414 * 100vw);
        font-size: calc(16 / 414 * 100vw);
        transition: 0.5s all;
    }

    .eyeIcon:hover {
        transform: scale(1.1);
    }

    .button {
        width: 100%;
        padding: calc(15 / 414 * 100vw);
        background-color: #de374b;
        color: #fff;
        font-weight: bold;
        text-align: center;
        border-radius: calc(10 / 414 * 100vw);
        cursor: pointer;
        transition: 0.5s all;
        outline: none;
        border: none;
        font-size: calc(14 / 414 * 100vw);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button:hover {
        opacity: 0.5;
    }

    .forgotPassword,
    .prompt {
        margin-top: calc(30 / 414 * 100vw);
        text-align: center;
        color: #de374b;
        font-size: calc(16 / 414 * 100vw);
        cursor: pointer;
        font-weight: bold;
    }


}