.convertContainer {
    width: 100%;
    height: fit-content;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.238);
    background: #de374b;
    border-radius: calc(10 / 1512 * 100vw);
    width: 100%;
    padding: calc(30 / 1512 * 100vw);
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.standAlonePageConvertContainer {
    width: 50%;
    margin-top: calc(50 / 1512 * 100vw);
}

.button {
    width: 100%;
    height: fit-content;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.238);
    background: #de374b;
    border-radius: calc(10 / 1512 * 100vw);
    width: 100%;
    padding: 0 calc(10 / 1512 * 100vw);
    height: calc(50 / 1512 * 100vw);
    outline: none;
    border: none;
    margin-top: calc(10 / 1512 * 100vw);
    color: #fff;
    font-size: calc(16 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.standAlonePageButton {
    width: 50%;
}

.button:hover {
    opacity: 0.8;
}

.inputContainer {
    width: 100%;
    background: #fff;
    height: calc(50 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.238);
    padding: 0 calc(10 / 1512 * 100vw);
    border: calc(3 / 1512 * 100vw) solid #fff;
    transition: 0.5s all;
}

.inputContainer:hover {
    border-color: #de374b;
}

.input {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    font-size: calc(14 / 1512 * 100vw);
    background: #fff;
}


.smallInputContainersGrouped {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: calc(20 / 1512 * 100vw);
}

.smallInputContainer {
    background: #fff;
    height: calc(50 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    padding: 0 calc(10 / 1512 * 100vw);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.238);
    width: 48%;
}


.resultContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000ce;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 0;
    padding: calc(10 / 1512 * 100vw);

}

.resultSubContainer {
    width: 50%;
    background: #fff;
    border-radius: calc(10 / 1512 * 100vw);

}

.resultLabel {
    text-align: center;
    margin: calc(20 / 1512 * 100vw);
    font-size: calc(20 / 1512 * 100vw);
    font-weight: bold;
    color: #de374b;
}

.closeIcon {
    width: calc(40 / 1512 * 100vw);
    height: calc(40 / 1512 * 100vw);
    margin-bottom: calc(20 / 1512 * 100vw);
    color: #fff;
    cursor: pointer;
    transition: 0.5s all;
}

.closeIcon:hover {
    transform: scale(1.1);
    color: red;
}


@media screen and (max-width: 480px) {
    .convertContainer {
        width: 100%;
        height: fit-content;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.238);
        background: #de374b;
        border-radius: calc(10 / 414 * 100vw);
        width: 100%;
        padding: calc(30 / 414 * 100vw);
    }

    .button {
        width: 100%;
        height: fit-content;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.238);
        background: #de374b;
        border-radius: calc(10 / 414 * 100vw);
        width: 100%;
        padding: 0 calc(10 / 414 * 100vw);
        height: calc(50 / 414 * 100vw);
        outline: none;
        border: none;
        margin-top: calc(10 / 414 * 100vw);
        color: #fff;
        font-size: calc(16 / 414 * 100vw);
        cursor: pointer;
        transition: 0.5s all;
    }

    .button:hover {
        opacity: 0.8;
    }

    .inputContainer {
        width: 100%;
        background: #fff;
        height: calc(50 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.238);
        padding: 0 calc(10 / 414 * 100vw);
        border: calc(3 / 414 * 100vw) solid #fff;
        transition: 0.5s all;
    }

    .inputContainer:hover {
        border-color: #de374b;
    }

    .input {
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
        font-size: calc(14 / 414 * 100vw);
    }


    .smallInputContainersGrouped {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: calc(20 / 414 * 100vw);
    }

    .smallInputContainer {
        background: #fff;
        height: calc(50 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
        padding: 0 calc(10 / 414 * 100vw);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.238);
        width: 48%;
    }

    .standAlonePageConvertContainer {
        width: 100%;
        margin-top: calc(20 / 414 * 100vw);
    }


    .resultContainer {
        padding: calc(10 / 424 * 100vw);
    }

    .resultSubContainer {
        width: 100%;
        background: #fff;
        border-radius: calc(10 / 414 * 100vw);

    }

    .resultLabel {
        text-align: center;
        margin: calc(20 / 414 * 100vw);
        font-size: calc(20 / 414 * 100vw);
        font-weight: bold;
        color: #de374b;
    }

    .closeIcon {
        width: calc(40 / 414 * 100vw);
        height: calc(40 / 414 * 100vw);
        margin-bottom: calc(20 / 414 * 100vw);
        color: #fff;
        cursor: pointer;
        transition: 0.5s all;
    }

    .closeIcon:hover {
        transform: scale(1.1);
        color: red;
    }


}