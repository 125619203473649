.container {
    position: relative;
    /* height: calc(100vh - calc(140 / 1512 * 100vw)); */
    width: 30%;
}

.title,
.oddsTitle {
    font-size: calc(16 / 1512 * 100vw);
    color: gray;
    font-weight: bold;
}


.title {
    width: 70%;
}

.oddsTitle {
    margin-top: calc(10 / 1512 * 100vw);
}



.skeletonContainer {
    width: calc(150 / 1512 * 100vw);
    height: calc(150 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    overflow: hidden;
}



.punterImage {
    width: calc(50 / 1512 * 100vw);
    height: calc(50 / 1512 * 100vw);
    border-radius: 100%;
    object-fit: cover;
    object-position: top;
}

.groupedPunters {
    display: flex;
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
    margin: calc(5 / 1512 * 100vw) 0;
    width: 100%;
    overflow: auto;
    border-radius: calc(10 / 1512 * 100vw);
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.groupedPunters::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.punterContainer {
    padding: calc(10 / 1512 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: calc(10 / 1512 * 100vw);
    align-items: center;
    padding: calc(10 / 1512 * 100vw);
    gap: calc(10 / 1512 * 100vw);
    background-color: rgba(255, 255, 255, 0.40);
    border: calc(2 / 1512 * 100vw) solid #ffffff00;
    cursor: pointer;
    transition: 0.5s all;

}



.punterContainer:hover {
    transform: scale(1.05);
}




.punterName {
    color: grey;
    font-size: calc(16 / 1512 * 100vw);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    width: calc(90 / 1512 * 100vw)
}

.ticketsContainer {
    margin: calc(20 / 1512 * 100vw) 0;
}

.clearFilter {
    display: flex;
    align-items: center;
    gap: calc(5 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
    color: red;
    cursor: pointer;
    transition: 0.5s all;
}




.groupedPrompt {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: calc(10 / 1512 * 100vw) 0;
}

.clearFilterIcon {
    width: calc(15 / 1512 * 100vw);
    height: calc(15 / 1512 * 100vw);
}

.smallText {
    font-size: calc(14 / 1512 * 100vw);
    color: gray;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ticketContainer {
    padding: calc(15 / 1512 * 100vw);
    margin-top: calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: rgba(255, 255, 255, 0.40);
}

.largeBoldText {
    font-size: calc(24 / 1512 * 100vw);
    font-weight: bold;
    text-transform: uppercase;
}

.redText {
    color: red;
    display: flex;
    align-items: center;
    font-size: calc(14 / 1512 * 100vw);
    font-weight: 300;
    gap: calc(5 / 1512 * 100vw);
}

.topContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.largeOdds {
    font-size: calc(18 / 1512 * 100vw);
    color: red;
    font-weight: bold;
    text-align: right;
}

.groupedTotalOddsGameOwner {
    display: flex;
    flex-direction: column;
    align-items: first flex-end;
}

.groupedGamesContainer {
    margin: calc(10 / 1512 * 100vw) 0;
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    max-height: calc(200 / 1512 * 100vw);
    overflow: hidden;
}

.gameContainer {
    background: #fff;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.prediction {
    font-size: calc(14 / 1512 * 100vw);
    margin-bottom: calc(2 / 1512 * 100vw);
}

.game {
    font-size: calc(16 / 1512 * 100vw);
    color: gray;
    font-weight: bold;
}

.timeStamp {
    font-size: calc(13 / 1512 * 100vw);
    color: gray;
}

.gameOdd {
    font-size: calc(14 / 1512 * 100vw);
}

.placeBetBtn {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background-color: #de374b;
    width: 100%;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(14 / 1512 * 100vw);
    color: #fff;
    transition: 0.5s all;
    cursor: pointer;
    border: calc(1.5 / 1512 * 100vw) solid #de374b;
}


.shareBtnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: calc(10 / 1512 * 100vw);
}

.shareBtn {
    padding: calc(10 / 1512 * 100vw);
    margin-top: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    width: 100%;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(14 / 1512 * 100vw);
    color: #de374b;
    transition: 0.5s all;
    border: calc(1.5 / 1512 * 100vw) solid #de374b;
    cursor: pointer;

}

.copyContainer {
    transition: 0.5 all;
    cursor: pointer;
}

.scrollable {
    height: calc(100vh - calc(350 / 1512 * 100vw));
    overflow: auto;
}

.groupedSkeletonTickets {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(20 / 1512 * 100vw);
    margin-top: calc(20 / 1512 * 100vw);
}

.ticketSkeleton {
    width: 100%;
    height: calc(350 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    overflow: hidden;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.refreshContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rotating {
    animation: rotate 1s linear infinite;
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.rotating_inactive {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.refresh {
    font-size: calc(14 / 1512 * 100vw);
}


.refresh {
    display: flex;
    gap: calc(5 / 1512 * 100vw);
    align-items: center;
    cursor: pointer;
    transition: 0.5s all;
}

.refresh:hover {
    transform: scale(1.1);
}

.noGameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: calc(10 / 1512 * 100vw);
    align-items: center;
}

.noGame {
    width: calc(200 / 1512 * 100vw);
    margin-top: calc(30 / 1512 * 100vw);
}

.noGameText {
    font-size: calc(18 / 1512 * 100vw);
    color: grey;
    width: 70%;
    text-align: center;
    font-weight: bold;
}


.clearFilter:hover,
.placeBetBtn:hover,
.shareBtn:hover,
.copyContainer:hover {
    opacity: 0.5
}

.loaderParentContainer {
    display: flex;
    justify-content: center;
}

.loaderCon {
    display: flex;
    align-self: center;

}

.viewAllBtn {
    margin-left: auto;
    font-size: calc(14 / 1512 * 100vw);
    font-weight: bold;
}

.seeAllContainer {
    display: flex;
    margin: calc(20 / 1512 * 100vw) 0;
    cursor: pointer;
    transition: 0.5s all;
}

.seeAllContainer:hover {
    opacity: 0.5;
}

.gamesModalContainer {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.147);
    top: 0;
    padding: calc(20 / 1512 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
}

.gamesModalSubContainer {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: #e2e2e2;
}


.modalGameContainerGrouped {
    margin: calc(10 / 1512 * 100vw) 0;
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    max-height: calc(300 / 1512 * 100vw);
    overflow: auto;
}

.bannerContainer {
    height: calc(100 / 1512 * 100vw);
}

.banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: calc(10 / 1512 * 100vw);

}

.closeModalBtn {
    background: #de374b;
    color: #fff;
    font-size: calc(14 / 1512 * 100vw);
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s all;
}

.closeModalBtn:hover {
    opacity: 0.5;
}

@media screen and (max-width: 480px) {
    .container {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .title,
    .oddsTitle {
        font-size: calc(16 / 414 * 100vw);
        color: gray;
        font-weight: bold;
        margin-bottom: calc(10 / 414 * 100vw);
    }

    .oddsTitle {
        margin-top: calc(20 / 414 * 100vw);
    }



    .skeletonContainer {
        width: calc(150 / 414 * 100vw);
        height: calc(150 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        overflow: hidden;
    }



    .punterImage {
        width: calc(50 / 414 * 100vw);
        height: calc(50 / 414 * 100vw);
        border-radius: 100%;
        object-fit: cover;
        object-position: top;
    }

    .groupedPunters {
        display: flex;
        align-items: center;
        gap: calc(10 / 414 * 100vw);
        margin: calc(5 / 414 * 100vw) 0;
        width: 100%;
        overflow: auto;
        border-radius: calc(10 / 414 * 100vw);
        /* For Firefox */
        -ms-overflow-style: none;
        /* For Internet Explorer and Edge */
    }

    .sticky {
        position: sticky;
        top: 5px;
        background: #ffffff;
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        margin-top: calc(10 / 414 * 100vw);
        border: calc(2 / 414 * 100vw) solid #fff;
        background: linear-gradient(177deg,
                /* rgba(237, 222, 213, 0.54) 1.27%, */
                #d3dcdf 36.96%,
                /* #eaddd1 63.74%, */
                #ddd9d7 96.63%);

    }



    .groupedPunters::-webkit-scrollbar {
        display: none;
        /* For Chrome, Safari, and Opera */
    }

    .punterContainer {
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw);
        gap: calc(5 / 414 * 100vw);
        border: calc(2 / 414 * 100vw) solid #ffffff00;
        /* background-color: rgba(135, 135, 135, 0.4); */
    }

    .punterContainer:hover {
        transform: initial;
    }

    .punterName {
        /* color: #fff; */
        font-size: calc(14 / 414 * 100vw);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        width: calc(70 / 414 * 100vw);
        font-weight: bold;
    }

    .punter_odds {
        width: initial;
    }

    .ticketsContainer {
        margin: calc(20 / 414 * 100vw) 0;
    }

    .clearFilter {
        display: flex;
        align-items: center;
        gap: calc(5 / 414 * 100vw);
        font-size: calc(14 / 414 * 100vw);
        color: red;
        cursor: pointer;
        transition: 0.5s all;
    }




    .groupedPrompt {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: calc(10 / 414 * 100vw) 0;
    }

    .clearFilterIcon {
        width: calc(15 / 414 * 100vw);
        height: calc(15 / 414 * 100vw);
    }

    .smallText {
        font-size: calc(14 / 414 * 100vw);
        color: gray;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .ticketContainer {
        padding: calc(15 / 414 * 100vw);
        margin-top: calc(20 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        background: rgba(255, 255, 255, 0.40);
    }

    .largeBoldText {
        font-size: calc(24 / 414 * 100vw);
        font-weight: bold;
        text-transform: uppercase;
    }

    .redText {
        color: red;
        display: flex;
        align-items: center;
        font-size: calc(14 / 414 * 100vw);
        font-weight: 300;
        gap: calc(5 / 414 * 100vw);
    }

    .topContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .largeOdds {
        font-size: calc(18 / 414 * 100vw);
        color: red;
        font-weight: bold;
    }

    .groupedTotalOddsGameOwner {
        display: flex;
        flex-direction: column;
        align-items: first flex-end;
    }

    .groupedGamesContainer {
        margin: calc(10 / 414 * 100vw) 0;
        display: flex;
        flex-direction: column;
        gap: calc(10 / 414 * 100vw);
        max-height: calc(200 / 414 * 100vw);
        overflow: hidden;
    }

    .gameContainer {
        background: #fff;
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .prediction {
        font-size: calc(14 / 414 * 100vw);
        margin-bottom: calc(2 / 414 * 100vw);
    }

    .game {
        font-size: calc(16 / 414 * 100vw);
        color: gray;
        font-weight: bold;
    }

    .timeStamp {
        font-size: calc(13 / 414 * 100vw);
        color: gray;
    }

    .gameOdd {
        font-size: calc(14 / 414 * 100vw);
    }

    .placeBetBtn {
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        background-color: #de374b;
        width: 100%;
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(14 / 414 * 100vw);
        color: #fff;
        transition: 0.5s all;
        cursor: pointer;
        border: calc(1.5 / 414 * 100vw) solid #de374b;
    }


    .shareBtnContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: calc(10 / 414 * 100vw);
    }

    .shareBtn {
        padding: calc(10 / 414 * 100vw);
        margin-top: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        width: 100%;
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(14 / 414 * 100vw);
        color: #de374b;
        transition: 0.5s all;
        border: calc(1.5 / 414 * 100vw) solid #de374b;
        cursor: pointer;

    }

    .copyContainer {
        transition: 0.5 all;
        cursor: pointer;
    }

    .scrollable {
        height: initial;
        overflow: auto;
    }

    .groupedSkeletonTickets {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: calc(20 / 414 * 100vw);
        margin-top: calc(20 / 414 * 100vw);
    }

    .ticketSkeleton {
        width: 100%;
        height: calc(350 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        overflow: hidden;
    }


    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .refreshContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .rotating {
        animation: rotate 1s linear infinite;
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }

    .rotating_inactive {
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }

    .refresh {
        display: none;
    }


    .noGameContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: calc(10 / 414 * 100vw);
        align-items: center;
    }

    .noGame {
        width: calc(200 / 414 * 100vw);
        margin-top: calc(30 / 414 * 100vw);
    }

    .noGameText {
        font-size: calc(18 / 414 * 100vw);
        color: grey;
        width: 70%;
        text-align: center;
        font-weight: bold;
    }


    .clearFilter:hover,
    .placeBetBtn:hover,
    .shareBtn:hover,
    .copyContainer:hover {
        opacity: 0.5
    }

    .loaderParentContainer {
        display: flex;
        justify-content: center;
    }

    .loaderCon {
        display: flex;
        align-self: center;

    }

    .allTickets {
        margin-bottom: calc(100 / 414 * 100vw);
    }


    .seeAllContainer {
        margin: calc(20 / 414 * 100vw) 0;
    }

    .gamesModalContainer {
        position: fixed;
        right: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.147);
        top: 0;
        left: 0;
        padding: calc(20 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .gamesModalSubContainer {
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        background: #e2e2e2;
    }


    .modalGameContainerGrouped {
        margin: calc(10 / 414 * 100vw) 0;
        display: flex;
        flex-direction: column;
        gap: calc(10 / 414 * 100vw);
        max-height: calc(300 / 414 * 100vw);
        overflow: auto;
    }

    .bannerContainer {
        height: calc(100 / 414 * 100vw);
    }

    .banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: calc(10 / 414 * 100vw);

    }


    .viewAllBtn {
        font-size: calc(14 / 414 * 100vw);
    }

    .closeModalBtn {
        background: #de374b;
        color: #fff;
        font-size: calc(14 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
    }


}