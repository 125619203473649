.container {
    padding: calc(10 / 1512 * 100vw);
}

.subContainer {
    padding: calc(10 / 1512 * 100vw);
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    color: #fff;
    align-items: center;
    border-radius: calc(10 / 1512 * 100vw);
    width: fit-content;
    font-weight: bold;
}

.arrowContainer {
    width: calc(30 / 1512 * 100vw);
    height: calc(30 / 1512 * 100vw);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s all;
}

.arrowContainer:hover {
    background: #ffffff5c;
}


@media screen and (max-width: 480px) {
    .container {
        padding: calc(10 / 414 * 100vw);
    }

    .subContainer {
        padding: calc(10 / 414 * 100vw);
        display: flex;
        gap: calc(10 / 414 * 100vw);
        color: #fff;
        align-items: center;
        border-radius: calc(10 / 414 * 100vw);
        width: fit-content;
        font-weight: bold;
    }

    .arrowContainer {
        width: calc(30 / 414 * 100vw);
        height: calc(30 / 414 * 100vw);
    }

    .arrowContainer:hover {
        background: #ffffff5c;
    }
}