.container {
    width: 100%;
    height: calc(80 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    box-shadow: 0 calc(4 / 1512 * 100vw) calc(10 / 1512 * 100vw) calc(3 / 1512 * 100vw) rgba(0, 0, 0, 0);
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    align-items: center;
}

.logo {
    width: calc(150 / 1512 * 100vw);
}

.button {
    border: calc(2 / 1512 * 100vw) solid #000;
    background-color: #000;
    height: calc(42 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
    transition: 0.5s all;
    cursor: pointer;
}

.button:hover {
    opacity: 0.5;
}

.groupedButtons {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: calc(20 / 1512 * 100vw);
}

.man {
    width: calc(50 / 1512 * 100vw);
    height: calc(50 / 1512 * 100vw);
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s all;
}

.man:hover {
    transform: scale(1.2);
}


.loaderCon {
    background: #de374b;
    padding: calc(10 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
}


@media screen and (max-width: 480px) {
    .container {
        width: 100%;
        height: calc(60 / 414 * 100vw);
        border-radius: 0;
        box-shadow: 0 calc(4 / 414 * 100vw) calc(10 / 414 * 100vw) calc(3 / 414 * 100vw) rgba(0, 0, 0, 0);
        background-color: rgba(255, 255, 255, 0.4);
        display: flex;
        padding: calc(35 / 414 * 100vw) calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        align-items: center;
    }

    .logo {
        width: calc(100 / 414 * 100vw);
    }

    .button {
        border: calc(2 / 414 * 100vw) solid #000;
        background-color: #000;
        height: calc(40 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: calc(10 / 414 * 100vw) calc(20 / 414 * 100vw);
        font-size: calc(12 / 414 * 100vw);
        transition: 0.5s all;
        cursor: pointer;
    }

    .button:hover {
        opacity: 0.5;
    }

    .groupedButtons {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: calc(10 / 414 * 100vw);
    }

    .man {
        width: calc(50 / 414 * 100vw);
        height: calc(50 / 414 * 100vw);
        border-radius: 100%;
        cursor: pointer;
        transition: 0.5s all;
    }

    .man:hover {
        transform: scale(1.2);
    }

    .loaderCon {
        background: #de374b;
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
    }


}