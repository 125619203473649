.container,
.containerOverlay {
    width: 100%;
    position: relative;
    height: calc(100vh - calc(140 / 1512 * 100vw));
    overflow: hidden;
    background-color: #de374b;
    padding: calc(20 / 1512 * 100vw);
    border-radius: calc(20 / 1512 * 100vw);
    background: rgba(255, 255, 255, 0.40);
}


.containerOverlay::before {
    content: '';
    position: absolute;
    background: #ffffffd6;
    width: 96%;
    height: 98%;
}



.modal {
    position: absolute;
    z-index: 100;
    width: 96%;
    height: 86%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}



.subModal {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
}

.subModal2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.238);
    background: #de374b;
    padding: calc(20 / 1512 * 100vw);
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    width: 100%;
    align-items: center;
}


.lockContainer {
    width: calc(60 / 1512 * 100vw);
    height: calc(60 / 1512 * 100vw);
    background: #ffffff4f;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vipConvertContainerGrouped {
    width: 100%;
    display: flex;
}

.convertCodeContainer {
    padding: calc(40 / 1512 * 100vw);
    width: 55%;
}


.vipContainer {
    width: 45%;
}


.firstQuestion {
    font-size: calc(20 / 1512 * 100vw);
    color: #fff;
    font-weight: bold;
    text-align: center;
}

.explanation {
    font-size: calc(16 / 1512 * 100vw);
    color: #ffffffac;
    text-align: center;
}

.explanation2 {
    font-size: calc(16 / 1512 * 100vw);
    display: flex;
    align-items: center;
    background: #ffffff4f;
    color: #fff;
    padding: calc(10 / 1512 * 100vw);
    gap: calc(5 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
}

.header {
    padding: calc(15 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    background: #de374b;
    color: #fff;
    font-size: calc(16 / 1512 * 100vw);
    font-weight: bold;
    border-radius: calc(10 / 1512 * 100vw);
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.lock {
    color: #de374b;
    /* color: #fff; */
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.subscribeButton {
    width: 100%;
    padding: calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    border: none;
    outline: none;
    background: #fff;
    font-weight: bold;
    font-size: calc(16 / 1512 * 100vw);
    gap: calc(5 / 1512 * 100vw);
    margin-top: calc(10 / 1512 * 100vw);
    align-items: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s all;
    justify-content: center;
}

.subscribeButton:hover {
    transform: scale(1.03);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.238);
    opacity: 0.8;
}


.title {
    font-size: calc(16 / 1512 * 100vw);
    color: gray;
    font-weight: bold;
}

.skeletonContainer {
    width: calc(150 / 1512 * 100vw);
    height: calc(150 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    overflow: hidden;
    background: 'red';
}

.punterContainer {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    gap: calc(10 / 1512 * 100vw);
    background-color: rgba(255, 255, 255, 0.40);
}

.punterImage {
    width: calc(100 / 1512 * 100vw);
    height: calc(100 / 1512 * 100vw);
    border-radius: 100%;
    object-fit: cover;
}

.groupedPunters {
    display: flex;
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
    margin: calc(5 / 1512 * 100vw) 0;
    width: 100%;
    overflow: auto;
    border-radius: calc(10 / 1512 * 100vw);
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.groupedPunters::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.punterContainer {
    padding: calc(10 / 1512 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.punterName {
    color: grey;
    font-size: calc(16 / 1512 * 100vw);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

.ticketsContainer {
    margin: calc(20 / 1512 * 100vw) 0;
}

.clearFilter {
    display: flex;
    align-items: center;
    gap: calc(5 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
    color: red;
    cursor: pointer;
    transition: 0.5s all;
}




.groupedPrompt {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: calc(10 / 1512 * 100vw) 0;
}

.clearFilterIcon {
    width: calc(15 / 1512 * 100vw);
    height: calc(15 / 1512 * 100vw);
}

.smallText {
    font-size: calc(14 / 1512 * 100vw);
    color: gray;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.subContainer {
    margin-top: calc(20 / 1512 * 100vw);
    display: flex;
    flex-direction: column;
    gap: calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    height: calc(100vh - calc(220 / 1512 * 100vw));
    overflow: auto;
    padding: calc(20 / 1512 * 100vw);

}




.ticketContainer {
    padding: calc(15 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: rgba(255, 255, 255, 0.40);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
    /* More pronounced shadow */
    background: #E8E4E3;

}

.largeBoldText {
    font-size: calc(24 / 1512 * 100vw);
    font-weight: bold;
    text-transform: uppercase;
}

.redText {
    color: red;
    display: flex;
    align-items: center;
    font-size: calc(14 / 1512 * 100vw);
    font-weight: 300;
    gap: calc(5 / 1512 * 100vw);
}

.topContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.largeOdds {
    font-size: calc(18 / 1512 * 100vw);
    color: red;
    font-weight: bold;
    text-align: right;
}

.groupedTotalOddsGameOwner {
    display: flex;
    flex-direction: column;
    align-items: first flex-end;
}

.groupedGamesContainer {
    margin: calc(10 / 1512 * 100vw) 0;
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    max-height: calc(200 / 1512 * 100vw);
    overflow: hidden;
}

.gameContainer {
    background: #fff;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.prediction {
    font-size: calc(14 / 1512 * 100vw);
    margin-bottom: calc(2 / 1512 * 100vw);
}

.game {
    font-size: calc(16 / 1512 * 100vw);
    color: gray;
    font-weight: bold;
}

.timeStamp {
    font-size: calc(13 / 1512 * 100vw);
    color: gray;
}

.gameOdd {
    font-size: calc(14 / 1512 * 100vw);
}

.placeBetBtn {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background-color: #de374b;
    width: 100%;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(14 / 1512 * 100vw);
    color: #fff;
    transition: 0.5s all;
    cursor: pointer;
    border: calc(1.5 / 1512 * 100vw) solid #de374b;
}


.shareBtnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shareBtn {
    padding: calc(10 / 1512 * 100vw);
    margin-top: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    width: 100%;
    /* width: 100%; */
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(14 / 1512 * 100vw);
    color: #de374b;
    transition: 0.5s all;
    border: calc(1.5 / 1512 * 100vw) solid #de374b;
    cursor: pointer;
}

.copyContainer {
    transition: 0.5 all;
    cursor: pointer;
}

.groupedSkeletonTickets {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(20 / 1512 * 100vw);
    margin-top: calc(20 / 1512 * 100vw);
}

.ticketSkeleton {
    width: 100%;
    height: calc(350 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    overflow: hidden;
}

.scrollView {
    height: calc(100vh - calc(420 / 1512 * 100vw));
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.refreshContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rotating {
    animation: rotate 1s linear infinite;
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.rotating_inactive {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.refresh {
    font-size: calc(14 / 1512 * 100vw);
}


.refresh {
    display: flex;
    gap: calc(5 / 1512 * 100vw);
    align-items: center;
    cursor: pointer;
    transition: 0.5s all;
}

.refresh:hover {
    transform: scale(1.1);
}

.description {
    text-transform: uppercase;
    font-weight: bold;
    font-size: calc(18 / 1512 * 100vw);
    color: #de374b;
}


.clearFilter:hover,
.placeBetBtn:hover,
.shareBtn:hover,
.copyContainer:hover {
    opacity: 0.5
}

.noGameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: calc(10 / 1512 * 100vw);
    align-items: center;
}

.noGame {
    width: calc(200 / 1512 * 100vw);
    margin-top: calc(30 / 1512 * 100vw);
}

.noGameText {
    font-size: calc(18 / 1512 * 100vw);
    color: grey;
    width: 70%;
    text-align: center;
    font-weight: bold;
}

.mobileHeaderSpan {
    display: none;
}



.viewAllBtn {
    margin-left: auto;
    font-size: calc(14 / 1512 * 100vw);
    font-weight: bold;
}

.seeAllContainer {
    display: flex;
    margin: calc(20 / 1512 * 100vw) 0;
    cursor: pointer;
    transition: 0.5s all;
}

.seeAllContainer:hover {
    opacity: 0.5;
}

.gamesModalContainer {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    top: 0;
    padding: calc(20 / 1512 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(24, 23, 23, 0.606);
}

.gamesModalSubContainer {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: #e2e2e2;
}


.modalGameContainerGrouped {
    margin: calc(10 / 1512 * 100vw) 0;
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    max-height: calc(300 / 1512 * 100vw);
    overflow: auto;
}



.closeModalBtn {
    background: #de374b;
    color: #fff;
    font-size: calc(14 / 1512 * 100vw);
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s all;
}

.closeModalBtn:hover {
    opacity: 0.5;
}



@media screen and (max-width: 480px) {
    .convertCodeContainer {
        display: none;
    }

    .container,
    .containerOverlay {
        width: 100%;
        position: relative;
        height: calc(100vh - calc(140 / 414 * 100vw));
        overflow: hidden;
        background-color: #de374b;
        padding: 0;
        border-radius: calc(10 / 414 * 100vw);
        background: none;
    }


    .containerOverlay::before {
        content: '';
        position: absolute;
        background: #ffffffd6;
        width: 100%;
        height: 98%;
    }



    .modal {
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 86%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }



    .subModal {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: calc(10 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw);
    }

    .subModal2 {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.238);
        background: #de374b;
        padding: calc(20 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        gap: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        width: 100%;
        align-items: center;
    }


    .lockContainer {
        width: calc(60 / 414 * 100vw);
        height: calc(60 / 414 * 100vw);
        background: #ffffff4f;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .vipConvertContainerGrouped {
        width: 100%;
        display: flex;
    }


    .vipContainer {
        width: 100%;
    }


    .firstQuestion {
        font-size: calc(20 / 414 * 100vw);
        color: #fff;
        font-weight: bold;
        text-align: center;
    }

    .explanation {
        font-size: calc(16 / 414 * 100vw);
        color: #ffffffac;
        text-align: center;
    }

    .explanation2 {
        font-size: calc(16 / 414 * 100vw);
        display: flex;
        align-items: center;
        background: #ffffff4f;
        color: #fff;
        padding: calc(10 / 414 * 100vw);
        gap: calc(5 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
    }

    .header {
        padding: calc(15 / 414 * 100vw) calc(10 / 414 * 100vw);
        background: #de374b;
        color: #fff;
        font-size: calc(16 / 414 * 100vw);
        font-weight: bold;
        border-radius: calc(10 / 414 * 100vw);
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .lock {
        color: #de374b;
        /* color: #fff; */
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }

    .subscribeButton {
        width: 100%;
        padding: calc(20 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        border: none;
        outline: none;
        background: #fff;
        font-weight: bold;
        font-size: calc(16 / 414 * 100vw);
        gap: calc(5 / 414 * 100vw);
        margin-top: calc(10 / 414 * 100vw);
        align-items: center;
        display: flex;
        cursor: pointer;
        transition: 0.5s all;
        justify-content: center;
    }

    .subscribeButton:hover {
        transform: scale(1.03);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.238);
        opacity: 0.8;
    }


    .title {
        font-size: calc(16 / 414 * 100vw);
        color: gray;
        font-weight: bold;
    }

    .skeletonContainer {
        width: calc(150 / 414 * 100vw);
        height: calc(150 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        overflow: hidden;
        background: 'red';
    }

    .punterContainer {
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        gap: calc(10 / 414 * 100vw);
        background-color: rgba(255, 255, 255, 0.40);
    }

    .punterImage {
        width: calc(100 / 414 * 100vw);
        height: calc(100 / 414 * 100vw);
        border-radius: 100%;
        object-fit: cover;
    }

    .groupedPunters {
        display: flex;
        align-items: center;
        gap: calc(10 / 414 * 100vw);
        margin: calc(5 / 414 * 100vw) 0;
        width: 100%;
        overflow: auto;
        border-radius: calc(10 / 414 * 100vw);
        /* For Firefox */
        -ms-overflow-style: none;
        /* For Internet Explorer and Edge */
    }

    .groupedPunters::-webkit-scrollbar {
        display: none;
        /* For Chrome, Safari, and Opera */
    }

    .punterContainer {
        padding: calc(10 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .punterName {
        color: grey;
        font-size: calc(16 / 414 * 100vw);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
    }

    .ticketsContainer {
        margin: calc(20 / 414 * 100vw) 0;
    }

    .clearFilter {
        display: flex;
        align-items: center;
        gap: calc(5 / 414 * 100vw);
        font-size: calc(14 / 414 * 100vw);
        color: red;
        cursor: pointer;
        transition: 0.5s all;
    }




    .groupedPrompt {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: calc(10 / 414 * 100vw) 0;
    }

    .clearFilterIcon {
        width: calc(15 / 414 * 100vw);
        height: calc(15 / 414 * 100vw);
    }

    .smallText {
        font-size: calc(14 / 414 * 100vw);
        color: gray;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .subContainer {
        margin-top: calc(20 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        gap: calc(20 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        height: calc(100vh - calc(220 / 414 * 100vw));
        overflow: auto;
        padding: 0;

    }




    .ticketContainer {
        padding: calc(15 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        background: rgba(255, 255, 255, 0.40);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
        /* More pronounced shadow */
        background: #E8E4E3;

    }

    .largeBoldText {
        font-size: calc(24 / 414 * 100vw);
        font-weight: bold;
        text-transform: uppercase;
    }

    .redText {
        color: red;
        display: flex;
        align-items: center;
        font-size: calc(14 / 414 * 100vw);
        font-weight: 300;
        gap: calc(5 / 414 * 100vw);
    }

    .topContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .largeOdds {
        font-size: calc(18 / 414 * 100vw);
        color: red;
        font-weight: bold;
    }

    .groupedTotalOddsGameOwner {
        display: flex;
        flex-direction: column;
        align-items: first flex-end;
    }

    .groupedGamesContainer {
        margin: calc(10 / 414 * 100vw) 0;
        display: flex;
        flex-direction: column;
        gap: calc(10 / 414 * 100vw);
        max-height: calc(200 / 414 * 100vw);
        overflow: hidden;
    }

    .gameContainer {
        background: #fff;
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .prediction {
        font-size: calc(14 / 414 * 100vw);
        margin-bottom: calc(2 / 414 * 100vw);
    }

    .game {
        font-size: calc(16 / 414 * 100vw);
        color: gray;
        font-weight: bold;
    }

    .timeStamp {
        font-size: calc(13 / 414 * 100vw);
        color: gray;
    }

    .gameOdd {
        font-size: calc(14 / 414 * 100vw);
    }

    .placeBetBtn {
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        background-color: #de374b;
        width: 100%;
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(14 / 414 * 100vw);
        color: #fff;
        transition: 0.5s all;
        cursor: pointer;
        border: calc(1.5 / 414 * 100vw) solid #de374b;
    }


    .shareBtnContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .shareBtn {
        padding: calc(10 / 414 * 100vw);
        margin-top: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        width: 100%;
        /* width: 100%; */
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(14 / 414 * 100vw);
        color: #de374b;
        transition: 0.5s all;
        border: calc(1.5 / 414 * 100vw) solid #de374b;
        cursor: pointer;
    }

    .copyContainer {
        transition: 0.5 all;
        cursor: pointer;
    }

    .groupedSkeletonTickets {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: calc(20 / 414 * 100vw);
        margin-top: calc(20 / 414 * 100vw);
    }

    .ticketSkeleton {
        width: 100%;
        height: calc(350 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        overflow: hidden;
    }

    .scrollView {
        height: calc(100vh - calc(420 / 414 * 100vw));
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .refreshContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .rotating {
        animation: rotate 1s linear infinite;
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }

    .rotating_inactive {
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }

    .refresh {
        font-size: calc(14 / 414 * 100vw);
    }


    .refresh {
        display: flex;
        gap: calc(5 / 414 * 100vw);
        align-items: center;
        cursor: pointer;
        transition: 0.5s all;
    }

    .refresh:hover {
        transform: scale(1.1);
    }

    .description {
        text-transform: uppercase;
        font-weight: bold;
        font-size: calc(18 / 414 * 100vw);
        color: #de374b;
    }


    .clearFilter:hover,
    .placeBetBtn:hover,
    .shareBtn:hover,
    .copyContainer:hover {
        opacity: 0.5
    }

    .noGameContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: calc(10 / 414 * 100vw);
        align-items: center;
    }

    .noGame {
        width: calc(200 / 414 * 100vw);
        margin-top: calc(30 / 414 * 100vw);
    }

    .noGameText {
        font-size: calc(18 / 414 * 100vw);
        color: grey;
        width: 70%;
        text-align: center;
        font-weight: bold;
        align-self: center;
    }

    .visibleOnlyDesktop {
        display: none;
    }


    .seeAllContainer {
        margin: calc(20 / 414 * 100vw) 0;
    }

    .gamesModalContainer {
        position: fixed;
        right: 0;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        padding: calc(20 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .gamesModalSubContainer {
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        background: #e2e2e2;
    }


    .modalGameContainerGrouped {
        margin: calc(10 / 414 * 100vw) 0;
        display: flex;
        flex-direction: column;
        gap: calc(10 / 414 * 100vw);
        max-height: calc(300 / 414 * 100vw);
        overflow: auto;
    }


    .viewAllBtn {
        font-size: calc(14 / 414 * 100vw);
    }

    .closeModalBtn {
        background: #de374b;
        color: #fff;
        font-size: calc(14 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
    }



}