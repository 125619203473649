.container {
    padding: calc(20 / 1512 * 100vw);
    width: 74%;
}

.subContainer {
    width: 80%;
    max-height: calc(100vh - calc(170 / 1512 * 100vw));
    overflow: auto;
}

.tabs {
    display: flex;
    margin-bottom: calc(20 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
}

.tabs button {
    margin-right: calc(10 / 1512 * 100vw);
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    border: none;
    cursor: pointer;
    background: #f0f0f0;
}

.tabs button.active {
    background: #de374b;
    color: white;
}

.inputContainer {
    width: 100%;
    background: #fff;
    height: calc(60 / 1512 * 100vw);
    padding: 0 calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    margin-bottom: calc(20 / 1512 * 100vw);
    display: flex;
    align-items: center;
}

.input {
    width: 100%;
    height: 100%;
    padding: calc(20 / 1512 * 100vw) 0;
    outline: none;
    font-size: calc(14 / 1512 * 100vw);
    border: none;
}


.input::placeholder {
    font-size: calc(14 / 1512 * 100vw);
    color: #a9a9a9;
}

.table {
    width: 100%;
    padding: calc(10 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
}

.table th,
.table td {
    padding: calc(10 / 1512 * 100vw);
    border: calc(1 / 1512 * 100vw) solid #838383;
    text-align: left;
}

.pagination {
    margin-top: calc(20 / 1512 * 100vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination button {
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    border: none;
    background: #de374b;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.pagination button:disabled {
    background: #ccc;
    cursor: not-allowed;
}


.loaderContainer {
    width: 100%;
    height: calc(100vh - calc(310 / 1512 * 100vw));
    border-radius: calc(20 / 1512 * 100vw);
    overflow: hidden;
}


.buttonContainer {
    background: #de374b;
    padding: calc(5 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.5s all;
}

.deleteBtn {
    color: #fff;
    width: calc(15 / 1512 * 100vw);
    height: calc(15 / 1512 * 100vw);
}

.buttonContainer:hover {
    opacity: 0.5;
}

.count {
    font-size: calc(16 / 1512 * 100vw);
    margin-bottom: calc(10 / 1512 * 100vw);
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000af;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlayCard {
    width: 40%;
    /* height: 40%; */
    background: #fff;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);

}

.editTitle {
    text-align: center;
    font-weight: bold;
    font-size: calc(16 / 1512 * 100vw);
}

.image_container {
    width: 100%;
    height: calc(400 / 1512 * 100vw);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
}

.image_container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000062;
    border-radius: calc(10 / 1512 * 100vw);
}

.text_image_uploader {
    font-size: calc(16 / 1512 * 100vw);
    color: #fff;
    text-align: center;
    width: 50%;
    position: relative;
    z-index: 2;
}

.punter_name_input {
    margin-top: calc(10 / 1512 * 100vw);
    width: 100%;
    padding: calc(15 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: #ebebebaf;
    outline: none;
    border: none;
}

.save {
    font-size: calc(14 / 1512 * 100vw);
    background: #de374b;
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    display: flex;
    border-radius: calc(10 / 1512 * 100vw);
    justify-self: center;
    margin-top: calc(10 / 1512 * 100vw);
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    transition: 0, 5s all;
}

.save:hover {
    opacity: 0.5;
}






@media screen and (max-width: 480px) {
    .container {
        padding: calc(10 / 414 * 100vw);
        width: 100%;
    }

    .subContainer {
        width: 100%;
        max-height: calc(100vh - calc(170 / 414 * 100vw));
        overflow: auto;
    }

    .tabs {
        display: flex;
        margin-bottom: calc(20 / 414 * 100vw);
        font-size: calc(14 / 414 * 100vw);
        font-weight: bold;
    }

    .tabs button {
        margin-right: calc(10 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw) calc(20 / 414 * 100vw);
        border: none;
        cursor: pointer;
        background: #f0f0f0;
    }

    .tabs button.active {
        background: #de374b;
        color: white;
    }

    .inputContainer {
        width: 100%;
        background: #fff;
        height: calc(60 / 414 * 100vw);
        padding: 0 calc(20 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        margin-bottom: calc(20 / 414 * 100vw);
        display: flex;
        align-items: center;
    }

    .input {
        width: 100%;
        height: 100%;
        padding: calc(20 / 414 * 100vw) 0;
        outline: none;
        font-size: calc(14 / 414 * 100vw);
        border: none;
    }


    .input::placeholder {
        font-size: calc(14 / 414 * 100vw);
        color: #a9a9a9;
    }

    .table {
        width: 100%;
        font-size: calc(14 / 414 * 100vw);

    }

    .table th,
    .table td {
        padding: calc(10 / 414 * 100vw);
        border: calc(1 / 414 * 100vw) solid #838383;
        text-align: left;
    }

    .pagination {
        margin-top: calc(20 / 414 * 100vw);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .pagination button {
        padding: calc(10 / 414 * 100vw) calc(20 / 414 * 100vw);
        border: none;
        background: #de374b;
        color: white;
        cursor: pointer;
        border-radius: 4px;
    }

    .pagination button:disabled {
        background: #ccc;
        cursor: not-allowed;
    }


    .loaderContainer {
        width: 100%;
        height: calc(100vh - calc(310 / 414 * 100vw));
        border-radius: calc(20 / 414 * 100vw);
        overflow: hidden;
    }


    .buttonContainer {
        background: #de374b;
        padding: calc(5 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        width: 100%;
        border: none;
        outline: none;
        cursor: pointer;
        transition: 0.5s all;
    }

    .deleteBtn {
        color: #fff;
        width: calc(15 / 414 * 100vw);
        height: calc(15 / 414 * 100vw);
    }

    .buttonContainer:hover {
        opacity: 0.5;
    }

    .count {
        font-size: calc(16 / 414 * 100vw);
        margin-bottom: calc(10 / 414 * 100vw);
    }

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000af;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(20 / 1512 * 100vw);
}

.overlayCard {
    width: 100%;
    /* height: 40%; */
    background: #fff;
    padding: calc(10 / 414 * 100vw);
    border-radius: calc(10 / 414 * 100vw);

}

.editTitle {
    text-align: center;
    font-weight: bold;
    font-size: calc(16 / 414 * 100vw);
}

.image_container {
    width: 100%;
    height: calc(400 / 414 * 100vw);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: calc(10 / 414 * 100vw);
    border-radius: calc(10 / 414 * 100vw);
}

.image_container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000062;
    border-radius: calc(10 / 414 * 100vw);
}

.text_image_uploader {
    font-size: calc(16 / 414 * 100vw);
    color: #fff;
    text-align: center;
    width: 50%;
    position: relative;
    z-index: 2;
}

.punter_name_input {
    margin-top: calc(10 / 414 * 100vw);
    width: 100%;
    padding: calc(15 / 414 * 100vw);
    border-radius: calc(10 / 414 * 100vw);
    background: #ebebebaf;
    outline: none;
    border: none;
}

.save {
    font-size: calc(14 / 414 * 100vw);
    background: #de374b;
    padding: calc(10 / 414 * 100vw) calc(20 / 414 * 100vw);
    display: flex;
    border-radius: calc(10 / 414 * 100vw);
    justify-self: center;
    margin-top: calc(10 / 414 * 100vw);
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    transition: 0, 5s all;
}

.save:hover {
    opacity: 0.5;
}

}