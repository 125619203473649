.container,
.containerInactive {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.519);
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(100 / 1512 * 100vw);
    top: 0;
    left: 0;
    opacity: 1;
}

.containerInactive {
    overflow: hidden;
    z-index: -1;
}



.subContainer,
.subContainerInactive {
    width: 30%;
    padding: calc(20 / 1512 * 100vw);
    border-radius: calc(20 / 1512 * 100vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    color: #fff;
    transition: 0.5s all;
}

.subContainerInactive {
    transform: translateY(-100vh);
}

.icon {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
    color: #fff;
}

.iconContainer {
    display: flex;
    padding: calc(10 / 1512 * 100VW);
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: none;
    gap: calc(10 / 1512 * 100vw);
}

.appButton {
    display: flex;
    flex-direction: column;
    padding: calc(10 / 1512 * 100VW);
    border-radius: calc(10 / 1512 * 100VW);
    align-items: center;
    font-size: calc(16 / 1512 * 100vw);
    border: none;
    gap: calc(10 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.appContainer {
    display: flex;
    flex-wrap: wrap;
    gap: calc(20 / 1512 * 100vw);
    margin-top: calc(20 / 1512 * 100vw);
    justify-content: center;
}

.closeIcon {
    width: calc(30 / 1512 * 100vw);
    height: calc(30 / 1512 * 100vw);
    color: rgb(254, 135, 135);
    margin-bottom: calc(20 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.closeIcon:hover {
    transform: scale(1.3);
}

.appButton:hover {
    opacity: 0.5;
}