.container {
    width: 74%;
    height: calc(100vh - calc(150 / 1512 * 100vw));
    background: rgba(255, 255, 255, 0.44);
    border-radius: calc(20 / 1512 * 100vw);
    padding-top: calc(100 / 1512 * 100vw);
    flex-direction: column;
    display: flex;
    align-items: center;
}

.head {
    font-size: calc(20 / 1512 * 100vw);
    margin-bottom: calc(20 / 1512 * 100vw);
    font-weight: bold;
    text-align: center;
}

.subContainer {
    width: 50%;
}

.inputContainer {
    width: 100%;
    margin-bottom: calc(20 / 1512 * 100vw);
}

.input {
    width: 100%;
    height: calc(60 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
    padding: calc(20 / 1512 * 100vw);
    outline: none;
    border: none;
    border-radius: calc(10 / 1512 * 100vw);
}

.quill {
    height: 200px;
    margin-bottom: calc(20 / 1512 * 100vw);
}

.button {
    width: 100%;
    height: calc(60 / 1512 * 100vw);
    margin-top: calc(70 / 1512 * 100vw);
    background-color: #de374b;
    color: white;
    border: none;
    border-radius: calc(10 / 1512 * 100vw);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

@media screen and (max-width: 480px) {
    .container {
        width: 100%;
        height: 65vh;
        background: rgba(255, 255, 255, 0.44);
        border-radius: calc(10 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw);
        padding-top: calc(50 / 414 * 100vw);
        flex-direction: column;
        display: flex;
        align-items: center;
    }

    .head {
        font-size: calc(18 / 414 * 100vw);
        margin-bottom: calc(20 / 414 * 100vw);
        font-weight: bold;
        text-align: center;
    }

    .subContainer {
        width: 100%;
    }

    .inputContainer {
        width: 100%;
        margin-bottom: calc(20 / 414 * 100vw);
    }

    .input {
        width: 100%;
        height: calc(60 / 414 * 100vw);
        padding: calc(20 / 414 * 100vw);
        font-size: calc(14 / 414 * 100vw);
        outline: none;
        border: none;
        border-radius: calc(10 / 414 * 100vw);
    }

    .quill {
        height: 200px;
        margin-bottom: calc(20 / 414 * 100vw);
    }

    .button {
        width: 100%;
        height: calc(50 / 414 * 100vw);
        margin-top: calc(90 / 414 * 100vw);
        background-color: #de374b;
        color: white;
        border: none;
        border-radius: calc(10 / 414 * 100vw);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(14 / 414 * 100vw);
    }

    .button:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }


}