.verifyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    text-align: center;
}

.successMessage {
    color: green;
    font-weight: bold;
}

.errorMessage {
    color: red;
    font-weight: bold;
}
