.overallContainer {
  background: linear-gradient(177deg,
      rgba(237, 222, 213, 0.54) 1.27%,
      #d3dcdf 36.96%,
      #eaddd1 63.74%,
      #ddd9d7 96.63%);
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  gap: calc(20 / 1512 * 100vw);
  padding: calc(20 / 1512 * 100vw);
}

.footerContainer {
  margin-top: auto;
  align-self: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 0 calc(10 / 1512 * 100vw);
}

.img {
  height: calc(40 / 1512 * 100vw);
  object-fit: contain;
}

.subContainer {
  display: flex;
  align-items: top;
  gap: calc(20 / 1512 * 100vw);
}

.vipIndexContainer {
  width: 70%;
}

.hamburgerMenu {
  display: none;
}


@media screen and (max-width: 480px) {



  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: top;
    gap: calc(20 / 414 * 100vw);
  }

  .container {
    gap: calc(20 / 414 * 100vw);
    padding: calc(12 / 414 * 100vw);
  }

  .footerContainer {
    margin-top: auto;
    align-self: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding: 0 calc(10 / 414 * 100vw);
  }



  .vipIndexContainer {
    width: 70%;
  }

  .vipIndexContainer {
    display: none;
  }

  .hamburgerMenu {
    display: flex;
    width: calc(30 / 414 * 100vw);
    height: calc(30 / 414 * 100vw);
  }

  .sideNav {
    /* background: red; */
    transition: 0.5s all;
    position: absolute;
    width: 90%;
    z-index: 1000;
  }

  .sideNavHidden {
    /* background: green; */
    transform: translateX(-110%);
  }

}