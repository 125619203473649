/* Skeleton.css */
.skeleton {
    background-color: #d2d2d2;
    border-radius: calc(4 / 1512 * 100vw);
    position: relative;
    overflow: hidden;
}

.skeleton::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(235, 234, 234, 0.8), rgba(255, 255, 255, 0));
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}
