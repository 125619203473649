.container {
    width: 100%;
    height: calc(100vh - calc(150 / 1512 * 100vw));
    background: #000;
    border-radius: calc(20 / 1512 * 100vw);
    overflow-y: auto;
}

.subContainer {
    display: flex;
    flex-direction: column;
    gap: calc(12 / 1512 * 100vw);
    color: #fff;
    padding: calc(20 / 1512 * 100vw);

}

.navContainer {
    display: flex;
    padding: calc(20 / 1512 * 100vw);
    border-radius: calc(20 / 1512 * 100vw);
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
    font-size: calc(16 / 1512 * 100VW);
    cursor: pointer;
    transition: 0.5s all;
}

.navContainer:hover {
    opacity: 0.7;
}

@media screen and (max-width: 480px) {
    .container {
        width: 100%;
        height: calc(100vh - calc(250 / 414 * 100vw));
        background: #000;
        border-radius: calc(10 / 414 * 100vw);
        overflow-y: auto;
    }

    .subContainer {
        display: flex;
        flex-direction: column;
        gap: calc(20 / 414 * 100vw);
        color: #fff;
        padding: calc(10 / 414 * 100vw);

    }

    .navContainer {
        display: flex;
        padding: calc(15 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        align-items: center;
        gap: calc(10 / 414 * 100vw);
        font-size: calc(14 / 414 * 100VW);
        cursor: pointer;
        transition: 0.5s all;
    }

    .navContainer:hover {
        opacity: 0.7;
    }
}